<template>
  <div class="card p-shadow-6">
    <h1>CRM Gráficos</h1>
    <div class="p-d-flex p-jc-end p-mr-2">
      <Button
        label="CRM"
        icon="pi pi-briefcase"
        class="p-button-raised p-button-text p-button-secondary"
        @click="$router.push(`/crm`)"
      />
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div v-if="can('seeGraphicLeadsBySatus')" class="p-col-12 p-md-4">
        <LeadsByStatus />
      </div>
      <div v-if="can('seeGraphicLeadsBySatusBySeller')" class="p-col-12 p-md-8">
        <LeadsByStatusAndSellers />
      </div>
      <div v-if="can('seeGraphicLeadsSales')" class="p-md-12">
        <divider />
        <Sales />
      </div>
      <div v-if="can('seeGraphicLeadsAndSalesBetweenDates')" class="p-md-12">
        <divider />
        <LeadsAndSalesByDates />
      </div>
    </div>
  </div>
</template>

<script>
import LeadsByStatus from "../components/crm/graphics/LeadsByStatus.vue";
import LeadsByStatusAndSellers from "../components/crm/graphics/LeadsByStatusAndSellers.vue";
import Sales from "../components/crm/graphics/Sales.vue";
import LeadsAndSalesByDates from "../components/crm/graphics/LeadsAndSalesByDates.vue";
export default {
  name: "CrmGraphics",
  components: {
    LeadsByStatus,
    LeadsByStatusAndSellers,
    Sales,
    LeadsAndSalesByDates,
  },
  data() {
    return {
      initGraphic: false,
      data: [],
    };
  },
  methods: {},
};
</script>
