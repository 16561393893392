<template>
  <div :style="{ position: 'relative' }">
    <Loading :active="loading" :isFullPage="false"></Loading>
    <Chart
      :type="graphicType"
      :data="chartData"
      :options="options"
    />
  </div>
</template>
<script>
import Chart from "primevue/chart";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import crmGraphicsService from "../../../services/crmGraphics.service";
export default {
  name: "CrmGraphicLeadByStatus",
  props: [],
  components: {
    Chart,
    Loading,
  },
  data() {
    return {
      loading: false,
      graphicType: "pie",
      data: [],
      chartData: {},
      options: {
        plugins: {
          title: {
            display: true,
            text: "Leads por Estado",
            font: {
              size: 24,
            },
          },
          legend: {
            position: "bottom",
            display: true,
            labels: {
              // generateLabels: function (chart) {
              //   var data = chart.data;
              //   return data.datasets[0].data.map(function (value, index) {
              //     return {
              //       text: data.labels[index] + " : " + value,
              //       fillStyle: data.datasets[0].backgroundColor[index],
              //       strokeStyle: data.datasets[0].backgroundColor[index],
              //       hidden: false,
              //       index: index,
              //     };
              //   });
              // },
            },
          },
        },
      },
    };
  },
  async created() {
    this.loading = true;
    this.data = await crmGraphicsService.dataLeadsByStatus();
    this.setChartData();
    this.setChartOptions();
    this.loading = false;
  },
  methods: {
    setChartData() {
      return (this.chartData = {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.leads_count,
            backgroundColor: this.data.colors,
          },
        ],
      });
    },
    setChartOptions() {
      return (this.options = {
        plugins: {
          title: {
            display: true,
            text: "Leads por Estado",
            font: {
              size: 24,
            },
          },
          legend: {
            position: "bottom",
            display: true,
            labels: {
              // generateLabels: function (chart) {
              //   var data = chart.data;
              //   return data.datasets[0].data.map(function (value, index) {
              //     return {
              //       text: data.labels[index] + " : " + value,
              //       fillStyle: data.datasets[0].backgroundColor[index],
              //       strokeStyle: data.datasets[0].backgroundColor[index],
              //       hidden: false,
              //       index: index,
              //     };
              //   });
              // },
            },
          },
        },
      });
    },
  },
};
</script>
