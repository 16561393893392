<template>
  <div :style="{ position: 'relative' }">
    <Loading :active="loading" :isFullPage="false"></Loading>
    <div class="p-md-12">
      <span class="p-d-flex p-jc-center">
        <h3 style="color: #666666; font-weight: bold">{{ title }}</h3>
      </span>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-2">
          <span class="p-d-flex p-ai-center">
            <label class="p-mr-2">Ver:</label>
            <Dropdown
              v-model="showBy"
              :options="[
                { value: 'leads', label: 'Leads Criadas' },
                { value: 'sales', label: 'Vendas Efetuadas' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="form-control"
              @change="hasChanges = true"
            >
            </Dropdown>
          </span>
        </div>
        <div class="p-col-12 p-md-2">
          <span class="p-d-flex p-ai-center">
            <label class="p-mr-2">Inicio:</label>
            <v-date-picker
              @input="hasChanges = true"
              v-model="startDate"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                  @change="hasChanges = true"
                />
              </template>
            </v-date-picker>
          </span>
        </div>
        <div class="p-col-12 p-md-2">
          <span class="p-d-flex p-ai-center">
            <label class="p-mr-2">Fim:</label>
            <v-date-picker
              v-model="endDate"
              @input="hasChanges = true"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </span>
        </div>
        <div class="p-col-12 p-md-2">
          <Button
            icon="pi pi-eye"
            class="p-mr-2"
            @click="getData"
            v-if="hasChanges"
            v-tooltip="'Ver Resultado'"
            />
            <Button
              icon="pi pi-download"
              @click="downloadResult"
              v-tooltip="'Download Resultado'"
            />
        </div>
      </div>
      <div style="max-height: 350px">
        <DataTable
          ref="dtCrmGraphicLeadsAndSalesByDates"
          :value="leadsList"
          filterDisplay="row"
          class="p-datatable-sm p-mt-3"
          :sortOrder="1"
          responsiveLayout="scroll"
          removableSort
          sortMode="single"
          :scrollable="true"
          scrollHeight="flex"
          :autoLayout="false"
        >
          <template #empty>
            <span v-if="hasChanges">
              Clique no botão para visualizar o resultado
            </span>
            <span v-else> Sem Leads. </span>
          </template>
          <template #loading> A carregar. Por favor Aguarde... </template>
          <Column
            field="subject"
            header="Descrição"
            sortable
            :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
            :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
          >
            <template #body="slotProps">
              <span
                class="clickable-item"
                v-tooltip="'Ver Lead'"
                @click="viewLead(slotProps.data.id)"
                >{{ slotProps.data.subject }}</span
              >
            </template>
          </Column>
          <Column
            field="seller.name"
            header="Vendedor"
            sortable
            :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          >
          </Column>
          <Column
            field="proposal_status.name"
            header="Estado"
            sortable
            :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          >
          </Column>
          <Column
            field="type_client.name"
            header="Cliente"
            sortable
            :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          >
          </Column>
          <Column
            header="Valores (Venda / Mensalidade / Custo)"
            :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          >
            <template #body="slotProps">
              {{
                toEuro(
                  slotProps.data.sale_value ? slotProps.data.sale_value : 0
                )
              }}
              /
              {{
                toEuro(
                  slotProps.data.monthly_sales_value
                    ? slotProps.data.monthly_sales_value
                    : 0
                )
              }}
              /
              {{ toEuro(slotProps.data.cost ? slotProps.data.cost : 0) }}
            </template>
          </Column>
          <Column
            v-if="showBy === 'leads'"
            field="created_at"
            header="Data Criação"
            sortable
            :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.created_at) }}
            </template>
          </Column>
          <Column
            v-if="showBy === 'sales'"
            field="sale_date"
            header="Data Venda"
            sortable
            :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          >
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { getOnlyDate, formatNumber } from "../../../helpers/helpers";
import crmGraphicsService from "../../../services/crmGraphics.service";
export default {
  name: "CrmGraphicLeadsAndSalesByDates",
  props: [],
  components: {
    Loading,
  },
  watch: {
    hasChanges() {
      if (this.hasChanges) {
        this.leadsList = [];
      }
    },
  },
  data() {
    return {
      title: "Vendas e Leads Criadas",
      hasChanges: false,
      showBy: "sales",
      loading: false,
      leadsList: [],
      startDate: null,
      endDate: null,
    };
  },
  async created() {
    this.loading = true;
    this.startDate = this.firstDayWeek();
    this.endDate = this.lastDayWeek();
    await this.getData();
    this.loading = false;
  },
  methods: {
    firstDayWeek() {
      const date = new Date();
      const day = date.getDay();
      const diff = date.getDate() - day + (day == 0 ? -6 : 1);
      return new Date(date.setDate(diff));
    },
    lastDayWeek() {
      const date = new Date();
      const day = date.getDay();
      const diff = date.getDate() + (7 - day);
      return new Date(date.setDate(diff));
    },
    formatDate(date) {
      return getOnlyDate(new Date(date));
    },
    toEuro(value) {
      return `${formatNumber(value)} €`;
    },
    viewLead(id) {
      let route = this.$router.resolve({
        path: `/crm/lead/${id}`,
      });
      window.open(route.href, "_blank");
    },
    async getData() {
      this.loading = true;
      this.leadsList = [];
      this.hasChanges = false;
      if (this.showBy === "leads") {
        this.leadsList = await crmGraphicsService.leadsCreatedBetweenDates(
          getOnlyDate(this.startDate),
          getOnlyDate(this.endDate)
        );
      } else if (this.showBy === "sales") {
        this.leadsList = await crmGraphicsService.salesBetweenDates(
          getOnlyDate(this.startDate),
          getOnlyDate(this.endDate)
        );
      }
      return (this.loading = false);
    },
    downloadResult() {
      this.$refs.dtCrmGraphicLeadsAndSalesByDates.exportCSV();
    },
  },
};
</script>
