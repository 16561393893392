<template>
  <div :style="{ position: 'relative' }" class="p-fluid p-formgrid p-grid">
    <div class="p-md-8">
      <Loading :active="loading" :isFullPage="false"></Loading>
      <Chart :type="graphicType" :data="chartData" :options="options" />
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-3">
          <Dropdown
            v-model="graphicType"
            :options="[
              { value: 'line', label: 'Gráfico de Linhas' },
              { value: 'bar', label: 'Gráfico de Barras' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="form-control"
          >
          </Dropdown>
        </div>
        <div class="p-col-12 p-md-3">
          <Dropdown
            v-model="valueBy"
            :options="[
              { value: 'sales', label: 'Valor das Vendas' },
              { value: 'monthly', label: 'Valor das Mensalidades' },
              { value: 'cost', label: 'Valor de Custo das Vendas' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="form-control p-ml-1"
            @change="setChartData"
          >
          </Dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <MonthPickerInput
            :default-month="month"
            :default-year="year"
            :editable-year="true"
            lang="pt"
            @change="initData"
          />
        </div>
        <div class="p-col-12 p-md-3">
          <Dropdown
            v-model="groupBy"
            :options="[
              { value: 'seller', label: 'Vendedor' },
              { value: 'clientType', label: 'Tipo de Cliente' },
              { value: 'closingProbability', label: 'Probabilidade de Fecho' },
              { value: 'category', label: 'Categoria' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="form-control p-ml-1"
            @change="initData"
          >
          </Dropdown>
        </div>
      </div>
    </div>
    <div class="p-md-4">
      <div style="height: 100%">
      <DataTable
        :value="leadsList"
        filterDisplay="row"
        :loading="loadingLeads"
        class="p-datatable-sm"
        :sortOrder="1"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="false"
      >
      <template #header>Leads</template>
        <template #empty>Sem Leads. Click no gráfico para ver</template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="description"
          header="Descrição"
          sortable
          :headerStyle="{ 'min-width': '75%', 'max-width': '75%' }"
          :bodyStyle="{ 'min-width': '75%', 'max-width': '75%' }"
        >
        </Column>
        <Column
          field="seller.name"
          header="Vendedor"
          sortable
          :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
        >
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              icon="pi pi-eye"
              v-tooltip.left="'ver'"
              class="p-button-sm p-button-rounded"
              @click="goLead(slotProps.data.id)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    </div>
  </div>
</template>
<script>
import Chart from "primevue/chart";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import crmGraphicsService from "../../../services/crmGraphics.service";
import { MonthPickerInput } from "vue-month-picker";
export default {
  name: "CrmGraphicSales",
  props: [],
  components: {
    MonthPickerInput,
    Chart,
    Loading,
  },
  data() {
    return {
      loading: false,
      loadingLeads: false,
      graphicType: "line",
      groupBy: "seller",
      valueBy: "sales",
      year: null,
      month: null,
      data: [],
      chartData: {},
      options: {},
      leadsList:[]
    };
  },
  async created() {
    let date = new Date();
    this.month = date.getMonth();
    this.year = date.getFullYear();
  },
  methods: {
    async initData(info) {
      if (
        info != undefined &&
        info.monthIndex != undefined &&
        info.year != undefined
      ) {
        this.month = info.monthIndex;
        this.year = info.year;
      }
      if (this.month === null || this.year === null) {
        return;
      }
      this.loading = true;
      await this.getData();
      this.setChartData();
      this.setChartOptions();
      this.loading = false;
    },
    async getData() {
      if (this.groupBy == "seller") {
        return (this.data = await crmGraphicsService.dataSalesSellers(
          this.year,
          this.month
        ));
      } else if (this.groupBy == "clientType") {
        return (this.data = await crmGraphicsService.dataSalesClientType(
          this.year,
          this.month
        ));
      } else if (this.groupBy == "closingProbability") {
        return (this.data = await crmGraphicsService.dataSalesProbability(
          this.year,
          this.month
        ));
      } else if (this.groupBy == "category") {
        return (this.data = await crmGraphicsService.dataSalesCategories(
          this.year,
          this.month
        ));
      } else {
        return (this.data = []);
      }
    },
    setChartData() {
      return (this.chartData = {
        labels: this.data.labels,
        datasets: this.getDatasets(),
      });
    },
    getDatasets() {
      let datasets = [];
      for (let i = 0; i < this.data.datasets.length; i++) {
        let dataset = {
          label: this.data.datasets[i].label,
          item_id: this.data.datasets[i].item_id,
          data: this.data.datasets[i].data[this.valueBy],
          fill: false,
          borderColor: this.data.datasets[i].color,
          backgroundColor: this.data.datasets[i].color,
          tension: 0.4,
        };
        datasets.push(dataset);
      }
      return datasets;
    },
    setChartOptions() {
      return (this.options = {
        responsive: true,
        onClick: (event, element) => {
          if (element.length == 0) {
            return;
          }
          let date = this.chartData.labels[ element[0].index];
          let itemId = this.chartData.datasets[element[0].datasetIndex].item_id;
          return this.getLeads(date, itemId);
        },
        plugins: {
          title: {
            display: true,
            text: "Vendas Previstas",
            font: {
              size: 24,
            },
          },
          legend: {
            position: "bottom",
            display: true,
          },
        },
        scales: {
          y: {
            type: "linear",
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              callback: function (value) {
                if (Number.isInteger(value)) {
                  return `${value} €`;
                }
              },
            },
          },
        },
      });
    },
    async getLeads(date, itemId) {
      this.loadingLeads = true;
      let params = {
        itemId: itemId,
        searchBy: this.groupBy,
      };
      if (date != "Sem Data") {
        params.yearMonth = date;
      }
      this.leadsList = await crmGraphicsService.leadsBySaleDate(params)
      return this.loadingLeads = false;
    },
    goLead(leadId) {
      let route = this.$router.resolve({
        path: `/crm/lead/${leadId}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style>
.month-picker-input {
  height: 33px;
  color: #495057;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
month-picker-input-container {
  width: 100% !important;
}
.month-picker__container {
  z-index: 50;
}
</style>
